import { fetchHTML } from "./../js/helper_functions";
import { Controller } from "stimulus";
import tippy from "tippy.js";
import { formatMoney } from "../../application/js/accounting";

export default class extends Controller {
  static targets = [
    "consultationType",
    "consultationPrice",
    "bookButton",
    "datepicker",
    "calendarLink",
    "promotionBlock",
    "promotionInput",
    "promotionAccordion",
    "attendanceModes",
    "consultationTypeAndTimeSlots"
  ]

  initialize() {
    // When navigating back in browser previously selected inputs are reselected
    // Need setTimeout so that the navigation preselection of form inputs finishes
    setTimeout(() => {
      this.attendanceMode = this.attendanceModesTargets.find(radio => radio.checked).value;
      this.consultationTypeId = this.consultationTypeTargets.find(radio => radio.checked).value;
      this.promotionCode = this.promotionInputTarget.value;

      this.refreshConsultationTypesAndTimeslots();
      this.updateAppointmentPrice();
      this.applyExistingPromotion();
    }, 0);
    tippy('.book-appointment-bulk-billing');
  }

  connect() {
    document.addEventListener("promotion_validator:success", this.applyPromotion);
    document.addEventListener("promotion_validator:error", this.removePromotion);
    document.addEventListener("listing_flatpickr:changed", this.onDateSelect);
  }

  disconnect() {
    document.removeEventListener("promotion_validator:success", this.applyPromotion);
    document.removeEventListener("promotion_validator:error", this.removePromotion);
    document.removeEventListener("listing_flatpickr:changed", this.onDateSelect);
  }

  onDateSelect = e => {
    const { dateStr } = e.detail;
    this.date = dateStr;
    this.refreshConsultationTypesAndTimeslots();
  }

  applyExistingPromotion = () => {
    if (this.promotionInputTarget.value) {
      this.promotionAccordionTarget.classList.add("open");
    }
  }

  onChangeConsultationType = event => {
    this.consultationTypeId = event.currentTarget.value;
    this.refreshConsultationTypesAndTimeslots();
    this.updateAppointmentPrice();
  }

  onChangeAttendanceMode = event => {
    this.attendanceMode = event.currentTarget.value;
    this.refreshConsultationTypesAndTimeslots();
  }

  onChangeTime = event => {
    this.startsAt = event.currentTarget.value;
    this.updateBookingUrl();
  }

  applyPromotion = event => {
    this.promotionCode = event.detail.promotion.code;
    this.updateConsultationPrices(event.detail.consultation_types);
    this.updateAppointmentPrice();
    this.updateBookingUrl();
  }

  removePromotion = event => {
    this.promotionCode = null;
    this.resetPrices();
    this.updateBookingUrl();
  }

  updateConsultationPrices = consultation_types => {
    consultation_types.forEach(({ id, price, discounted_price }) => {
      const radio = document.getElementById(`consultation_type_id_${id}`);
      if(!!radio) {
        radio.dataset.price = `${formatMoney(discounted_price)}`;
        radio.dataset.originalPrice = `${formatMoney(price)}`;
        const price_span = radio.nextElementSibling.querySelector('.book-appointment-service-price');
        price_span.innerHTML = `<s>${formatMoney(price)}</s>&nbsp;${formatMoney(discounted_price)}`;
      }
    })
  }

  resetPrices = () => {
    const radios = document.querySelectorAll('[name="consultation_type_id"]');
    radios.forEach(radio => {
      const originalPrice = radio.dataset.originalPrice;
      if (originalPrice) {
        radio.dataset.price = originalPrice;
        const price_span = radio.nextElementSibling.querySelector('.book-appointment-service-price');
        price_span.innerHTML = originalPrice;
      }
    })
    this.updateAppointmentPrice();
  }

  updateAppointmentPrice = () => {
    if(!this.hasConsultationPriceTarget) return;

    const selected = this.consultationTypeTargets.find(radio => radio.checked);
    const price = selected.dataset.price;
    this.consultationPriceTarget.innerHTML = price;
  }

  updateConsultationTypesAndTimeslots = html => {
    const selectedId = document.querySelector('[name="consultation_time"]:checked')?.id;

    this.consultationTypeAndTimeSlotsTarget.innerHTML = html;
    const previousTimeslot = document.getElementById(selectedId);
    if (previousTimeslot) {
      previousTimeslot.checked = true;
    }
    this.startsAt = document.querySelector('[name="consultation_time"]:checked')?.value;
  }

  updateBookingUrl = () => {
    let url = new URL(this.url);
    url.searchParams.append("attendance_mode", this.attendanceMode);
    url.searchParams.append("consultation_type_id", this.consultationTypeId);
    url.searchParams.append("practitioner_id", this.practitionerId);
    url.searchParams.append("starts_at", this.startsAt);
    if (this.promotionCode) {
      url.searchParams.append("promotion_code", this.promotionCode);
    }
    this.bookButtonTarget.href = url;
  }

  refreshConsultationTypesAndTimeslots = () => {
    this.fetchConsultationTypesAndTimeslots()
      .then(html => {
        this.updateConsultationTypesAndTimeslots(html);
        this.updateBookingUrl();
      })
      .catch(e => Rollbar.error(e))
  }

  fetchConsultationTypesAndTimeslots = () => {
    let url = new URL(window.location.href);
    url.searchParams.append("partial", "true");
    url.searchParams.append("attendance_mode", this.attendanceMode);
    url.searchParams.append("consultation_type_id", this.consultationTypeId);
    if (this.date) {
      url.searchParams.append("date", this.date)
    }

    return fetchHTML(url);
  }

  toggleBookingWindow = () => {
    if (window.innerWidth > 1080) {
      return;
    }
    const bookingElement = document.getElementById('booking-ui');
    const { className } = bookingElement;
    if (className.indexOf('open') === -1) {
      // Get current scroll position and set as top value to fixed body
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      // Store current top state
      document.body.style.top = `-${this.scrollTop}px`;
      document.body.classList.add('booking-ui-open');
      // Toggle booking element class and top
      bookingElement.style.top = "";
      bookingElement.classList.add('open');
    } else {
      // Get scroll position when locked and scroll back to same position after unfixing body
      document.body.style.top = '0px';
      document.body.classList.remove('booking-ui-open');
      window.scrollTo(0, this.scrollTop);
      // Toggle booking element class and top
      bookingElement.style.top = `${window.innerHeight - 94}px`;
      bookingElement.classList.remove('open');
    }
  }

  get url() {
    return this.data.get("url");
  }

  get promotionUrl() {
    return this.data.get("promotionUrl");
  }

  get practitionerId() {
    return this.data.get("practitionerId");
  }

  get consultationTypeId() {
    return this.data.get("consultationTypeId");
  }

  set consultationTypeId(id) {
    this.data.set("consultationTypeId", id);
  }

  get attendanceMode() {
    return this.data.get("attendanceMode");
  }

  set attendanceMode(mode) {
    this.data.set("attendanceMode", mode);
  }

  get startsAt() {
    return this.data.get("startsAt");
  }

  set startsAt(time) {
    this.data.set("startsAt", time);
  }

  get scrollTop() {
    return this.data.get("scrollTop");
  }

  set scrollTop(value) {
    return this.data.set("scrollTop", value);
  }
}
